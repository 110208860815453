/********** banner ******/
.banner {
  background: #f5f8ff;
}
.banner .container {
  height: 560px;
  border-radius: 0px 0px 0px 0px;
  position: relative;
  background-image: url('https://picture.jwin100.cn/ant/index-banner.png');
  background-position: right 50px;
  background-size: 620px 456px;
  background-repeat: no-repeat;
}
.banner .container .title {
  width: 100%;
  font-size: 46px;
  font-weight: bold;
  color: #000000;
  margin-top: 132px;
}
.banner .container .title span {
  color: #3371ef;
}
.banner .container .introduce {
  max-width: 52%;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  margin-top: 54px;
}
.banner .container .free-registry {
  width: 100%;
  margin-top: 67px;
}
.banner .container .free-registry a {
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  background: #3371ef;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  width: 260px;
  height: 70px;
  border-radius: 70px 70px 70px 70px;
  line-height: 70px;
}
/********** 当前收银软件的问题 ******/
.matter {
  padding-top: 80px;
  background-color: #ffffff;
}
.matter .col-md-12 {
  padding: 18px 1px;
}
.matter h2 {
  font-weight: 400;
  font-size: 40px;
  color: #333333;
}
.matter .row {
  margin-top: 80px;
}
.matter .row .matter-item {
  min-height: 200px;
  border-radius: 0px 0px 0px 0px;
}
.matter .row .matter-item h3 {
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  margin-left: 24px;
  margin-top: 24px;
}
.matter .row .matter-item p {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-left: 24px;
  margin-top: 24px;
  margin-right: 24px;
  margin-bottom: auto;
}
.matter .row .matter-one {
  background: #f1f6ff;
  background-image: url(https://picture.jwin100.cn/ant/one.png);
  background-position: 78% bottom;
  background-size: auto;
  background-repeat: no-repeat;
}
.matter .row .matter-two {
  background: #dae8ff;
  background-image: url(https://picture.jwin100.cn/ant/two.png);
  background-position: 78% bottom;
  background-size: auto;
  background-repeat: no-repeat;
}
.matter .row .matter-three {
  background: #f1f6ff;
  background-image: url(https://picture.jwin100.cn/ant/three.png);
  background-position: 78% bottom;
  background-size: auto;
  background-repeat: no-repeat;
}
.matter .row .matter-four {
  background: #dae8ff;
  background-image: url(https://picture.jwin100.cn/ant/four.png);
  background-position: 78% bottom;
  background-size: auto;
  background-repeat: no-repeat;
}
/********** 我们的优势 ******/
.advantage {
  padding-top: 80px;
}
.advantage .col-md-12 {
  padding-left: 2px;
  padding-right: 2px;
}
.advantage h2 {
  font-weight: 400;
  font-size: 40px;
  color: #333333;
}
.advantage .row {
  margin-top: 80px;
}
.advantage .row .advantage-item {
  height: 330px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  padding: 40px 0 56px;
  margin-bottom: 40px;
}
.advantage .row .advantage-item .iconfont {
  color: #345afa;
  font-size: 64px;
}
.advantage .row .advantage-item h3 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  margin-top: 32px;
}
.advantage .row .advantage-item p {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-top: 32px;
  max-height: 66px;
  padding: 0 24px 0;
}
/********** 适用行业 ******/
.industry {
  background: #f8f8f8;
  padding-top: 80px;
  padding-bottom: 40px;
}
.industry .container .col-md-12 {
  padding: 0 22px 40px;
}
.industry .container h2 {
  font-weight: 400;
  font-size: 40px;
  color: #333333;
}
.industry .container .row {
  padding-top: 80px;
}
.industry .container .row .industry-item {
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.industry .container .row .industry-item h3 {
  z-index: 1;
  display: inline-block;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
  margin-top: 57px;
  position: relative;
}
.industry .container .row .industry-item p {
  display: inline-block;
  z-index: 1;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin-top: 24px;
  margin-left: 56px;
  margin-right: 56px;
  position: relative;
}
.industry .container .row .lingshou {
  background-image: url('https://picture.jwin100.cn/ant/lingshou.png');
}
.industry .container .row .canyin {
  background-image: url('https://picture.jwin100.cn/ant/canyin.png');
}
.industry .container .row .fuwu {
  background-image: url('https://picture.jwin100.cn/ant/fuwu.png');
}
.industry .container .row .dingzhi {
  background-image: url('https://picture.jwin100.cn/ant/dingzhi.png');
}
.industry .container .row .industry-item::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}
/********** 专业服务 ******/
.support {
  background: #f6f9ff;
  padding-top: 80px;
  padding-bottom: 80px;
}
.support .container h2 {
  font-weight: 400;
  font-size: 40px;
  color: #333333;
}
.support .container .row {
  padding-top: 80px;
}
.support .container .row .col-md-12 {
  padding-left: 4px;
  padding-right: 4px;
}
.support .container .row .support-item {
  height: 374px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
}
.support .container .row .support-item h3 {
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  padding: 40px 39px 16px;
  height: 90px;
}
.support .container .row .support-item img {
  width: 128px;
  height: 128px;
}
.support .container .row .support-item p {
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  padding: 16px 24px 24px;
}
/********** 邀请注册 ******/
.inviter {
  background-color: #ffffff;
}
.inviter .container .row {
  text-align: center;
}
.inviter .container .row .inviter-col {
  height: 480px;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.inviter .container .row .inviter-item {
  width: 50%;
}
.inviter .container .row .inviter-img {
  display: block;
}
.inviter .container .row h2 {
  font-weight: 400;
  font-size: 36px;
  color: #333333;
}
.inviter .container .row h3 {
  font-weight: 400;
  font-size: 36px;
  color: #333333;
  margin-top: 30px;
}
.inviter .container .row a {
  display: inline-block;
  width: 320px;
  height: 64px;
  border-radius: 80px 80px 80px 80px;
  background: #3371ef;
  font-size: 20px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  line-height: 64px;
  margin-top: 60px;
}
@media (max-width: 575.98px) {
  .banner .container {
    background-image: none;
    text-align: center;
  }
  .banner .container .title {
    font-size: 33px;
  }
  .banner .container .introduce {
    max-width: 100%;
    font-size: 21px;
  }
  .banner .container .free-registry a {
    width: 280px;
    height: 70px;
    border-radius: 70px 70px 70px 70px;
    line-height: 70px;
  }
  .matter h2 {
    font-size: 35px;
  }
  .advantage h2 {
    font-size: 35px;
  }
  .advantage .advantage-item {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  }
  .industry h2 {
    font-size: 35px;
  }
  .support {
    background: #ffffff;
    padding-bottom: 0;
  }
  .support h2 {
    font-size: 35px;
  }
  .inviter {
    background: #f5f8ff;
  }
  .inviter .container {
    text-align: center;
  }
  .inviter .container .row .inviter-col {
    height: 400px;
  }
  .inviter .container .row .inviter-item {
    width: 100% !important;
  }
  .inviter .container .row .inviter-img {
    display: none;
  }
  .inviter .container .row h2 {
    font-size: 35px;
  }
  .inviter .container .row h3 {
    font-size: 30px;
  }
  .inviter .container .row a {
    width: 300px;
    height: 60px;
    font-size: 18px;
    line-height: 60px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .banner .container {
    background-image: none;
    text-align: center;
  }
  .banner .container .introduce {
    max-width: 100%;
  }
  .advantage .advantage-item {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  }
  .inviter .container {
    text-align: center;
  }
  .inviter .container .row .inviter-item {
    width: 100% !important;
  }
  .inviter .container .row .inviter-img {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .banner .container {
    background-image: none;
    text-align: center;
  }
  .banner .container .introduce {
    max-width: 100%;
  }
  .advantage .advantage-item {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  }
  .inviter .container {
    text-align: center;
  }
  .inviter .container .row .inviter-item {
    width: 100% !important;
  }
  .inviter .container .row .inviter-img {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner .container .title {
    margin-left: 48px;
  }
  .banner .container .introduce {
    margin-left: 48px;
  }
  .banner .container .free-registry {
    margin-left: 120px;
  }
}
@media (min-width: 1200px) {
  .banner .container .title {
    margin-left: 48px;
  }
  .banner .container .introduce {
    margin-left: 48px;
  }
  .banner .container .free-registry {
    margin-left: 120px;
  }
}
